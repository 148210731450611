import { FC } from "react";
import NextHead from "next/head";
import { DefaultSeo } from "next-seo";
interface NextHeadTypes {
  title?: string;
  description?: string;
  robots?:
    | "noindex, nofollow"
    | "index, follow"
    | "noindex, follow"
    | "index, nofollow";
  siteName?: string;
  url?: string;
  font?: string;
  canonicalUrl?: string;
  image?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogUrl?: string;
  ogImage?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterUrl?: string;
  twitterImage?: string;
}

const Head: FC<NextHeadTypes> = ({
  title,
  description,
  image,
  ogDescription,
  ogImage,
  ogTitle,
  ogUrl,
  twitterDescription,
  twitterImage,
  twitterTitle,
  twitterUrl,
  font,
  canonicalUrl,
  siteName,
  url,
  robots,
}) => {
  const config = {
    title: title ?? "Tracify - AI Data Solution for Ecommerce",
    description:
      description ??
      "Our patent-pending AI technology lets you track every customer interaction on your website without invading their privacy. Cross-device, cross-domain, cross-session - no cookies required. iOS14 does not affect our tracking at all. Made and hosted 100% in Germany.",
    openGraph: {
      title: ogTitle ?? title ?? "Tracify - AI Data Solution for Ecommerce",
      description:
        ogDescription ??
        description ??
        "Our patent-pending AI technology lets you track every customer interaction on your website without invading their privacy. Cross-device, cross-domain, cross-session - no cookies required. iOS14 does not affect our tracking at all. Made and hosted 100% in Germany.",
      type: "website",
      locale: "de_de",
      url: ogUrl ?? url ?? "https://www.tracify.ai",
      site_name: siteName ?? "Tracify AI",
      images: [
        {
          url: ogImage ?? image ?? "/logo500x84.png",
          width: 500,
          height: 84,
          alt: "Tracify AI Logo",
        },
      ],
    },
    twitter: {
      cardType: "summary_large_image",
    },
    canoncial: canonicalUrl ?? "",
  };

  return (
    <>
      <DefaultSeo {...config} />
      <NextHead>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1 maximum-scale=1"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#222131" />
        <meta name="msapplication-TileColor" content="#222131" />
        <meta name="theme-color" content="#ffffff" />
        {robots && <meta name="robots" content={robots} />}
      </NextHead>
    </>
  );
};

export default Head;
