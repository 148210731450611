import { Modal, Note, Spinner } from "@ui";
import {
  attributionModelAtom,
  attributionWindowAtom,
  dashboardModeAtom,
  endTimeAtom,
  newVsReturningAtom,
  openExportModalAtom,
  reportTimeAtom,
  selectedMarketingChannelsAtom,
  startTimeAtom,
  tablesToExportAtom,
  timeRangeKeyAtom,
} from "atoms";
import { useAtom } from "jotai";
import React, { useCallback, useMemo } from "react";
import dayjs from "dayjs";
import { useAnalytics } from "@lib/api-hooks/useAnalytics";
import { useAuth } from "@lib/api-hooks";
import { BaseScore } from "@api/types/backendTypes";
import DownloadFileButton from "./DownloadFileButton";
import { ExportType } from "interface/ExportType";
import { exportToCsv } from "@lib/util-functions/exports/csvExport";
import {
  CHANNELS_WITHOUT_BASESCORE_TYPE,
  CONSTANT_LABELS,
  pageLookup,
  realtimeDashboardSettings,
} from "constants/constants";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import { usePostHog } from "posthog-js/react";
import { useRouter } from "next/router";
import useCurrency from "@lib/hooks/use-currency";
import useFormat from "@lib/hooks/use-format";
import { Button } from "@components/ui/button";

const ExportModal = () => {
  const [newVsReturning] = useAtom(newVsReturningAtom);
  const { selectedOrganisationData } = useSelectedStores();
  const { isPending } = useAuth();
  const posthog = usePostHog();
  const { pathname, query } = useRouter();
  const page = pageLookup[pathname as keyof typeof pageLookup] || "";
  const [dashboardMode] = useAtom(dashboardModeAtom);
  const adsManagerSection =
    pathname === "/" ? null : query?.tabValue ?? "campaigns";
  const {
    isFetching: fetchingData,
    progress,
    finished,
    refetch: onFetchData,
    data,
  } = useAnalytics(
    {
      ...realtimeDashboardSettings(Boolean(newVsReturning !== "default")),
    },
    {
      enabled: !!selectedOrganisationData?.id,
    }
  );
  const adsData = useMemo(() => {
    if (newVsReturning !== "default") {
      return data?.nvr_tc_aggregated
        ? data.nvr_tc_aggregated[
            newVsReturning as keyof typeof data.nvr_tc_aggregated
          ]
        : undefined;
    }
    return data?.tc_aggregated;
  }, [newVsReturning, data]);

  const [exportModalOpen, setExportModalOpen] = useAtom(openExportModalAtom);
  const [tablesToExport, setTablesToExport] = useAtom(tablesToExportAtom);
  const [attributionWindow] = useAtom(attributionWindowAtom);
  const [attributionModel] = useAtom(attributionModelAtom);
  const [reportTime] = useAtom(reportTimeAtom);
  const [selectedMarketingChannels] = useAtom(selectedMarketingChannelsAtom);
  const [timerangeKey] = useAtom(timeRangeKeyAtom);
  const [startTime] = useAtom(startTimeAtom);
  const [endTime] = useAtom(endTimeAtom);
  const { calculateCurrencyConvertion } = useCurrency();
  const { formatNumber } = useFormat();
  const onModalClose = useCallback(() => {
    setExportModalOpen(false);
    setTablesToExport([]);
  }, [setExportModalOpen, setTablesToExport]);
  const isSameDate = dayjs(startTime).isSame(dayjs(endTime), "date");
  let content;

  const includesCurrentDay = useMemo(
    () =>
      dayjs(endTime).isSame(dayjs(), "date") ||
      dayjs(endTime).isAfter(dayjs(), "date"),
    [endTime]
  );

  const onDownloadFile = useCallback(
    ({
      channel,
      fileName,
      exportType,
    }: {
      channel: string;
      fileName: string;
      exportType: ExportType;
    }) => {
      let dataToDownload: BaseScore[] | undefined = [];
      if (channel === "all") {
        if (adsData?.campaigns) {
          dataToDownload = [...adsData?.campaigns?.entries()]
            .map((el) => el[1])
            .flat();
        }
      } else if (exportType === "campaigns" || exportType === "all") {
        dataToDownload = adsData?.campaigns?.get(channel);
      } else if (exportType === "adsets" && adsData) {
        // sorting by campaignId
        dataToDownload = adsData?.adsets?.get(channel)?.sort((a, b) => {
          let n = 0;
          if (a.parentCampaign?.refId && b.parentCampaign?.refId) {
            if (a.parentCampaign?.refId > b.parentCampaign?.refId) {
              n = -1;
            }
            if (b.parentCampaign?.refId > a.parentCampaign?.refId) {
              n = 1;
            }
            if (n === 0) {
              n = b.purchaseCount - a.purchaseCount;
            }
          }
          return n;
        });
      } else if (exportType === "ads" && adsData) {
        // sorting by campaignId and adsetId
        dataToDownload = adsData?.ads?.get(channel)?.sort((a, b) => {
          let n = 0;
          if (a.parentCampaign?.refId && b.parentCampaign?.refId) {
            if (a.parentCampaign?.refId > b.parentCampaign?.refId) {
              n = -1;
            }
            if (b.parentCampaign?.refId > a.parentCampaign?.refId) {
              n = 1;
            }
          }
          if (n === 0 && a.parentAdset?.refId && b.parentAdset?.refId) {
            if (a.parentAdset?.refId > b.parentAdset?.refId) {
              n = -1;
            }
            if (b.parentAdset?.refId > a.parentAdset?.refId) {
              n = 1;
            }
          }
          if (n === 0) {
            n = b.purchaseCount - a.purchaseCount;
          }
          return n;
        });
      }
      if (dataToDownload) {
        posthog?.capture("CSV Exported", {
          platform: "dashboard",
          page,
          section: adsManagerSection
            ? adsManagerSection
            : tablesToExport?.join(", "),
          exported_data: exportType,
          filename: fileName,
          attribution_model: attributionModel,
          attribution_window: attributionWindow,
          report_time: reportTime,
          touchpoints: selectedMarketingChannels,
          nvr: newVsReturning,
          date_preset:
            timerangeKey === "Custom"
              ? { start_date: startTime, end_date: endTime }
              : timerangeKey,
        });
        const csvData = exportToCsv(dataToDownload, {
          includesCurrentDay,
          exportType,
          formatNumber,
          calculateCurrencyConvertion,
          dashboardMode,
        });
        const blob = new Blob([csvData], { type: "text/csv" });
        const a = document.createElement("a");
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
      }
    },
    [
      adsData,
      posthog,
      page,
      adsManagerSection,
      tablesToExport,
      attributionModel,
      attributionWindow,
      reportTime,
      selectedMarketingChannels,
      newVsReturning,
      timerangeKey,
      startTime,
      endTime,
      includesCurrentDay,
      formatNumber,
      calculateCurrencyConvertion,
      dashboardMode,
    ]
  );

  if (isPending) {
    content = null;
  } else if (fetchingData && !finished) {
    content = (
      <div className="flex items-center justify-center mt-20">
        <Spinner />{" "}
        <span className="ml-2">Fetching Data ({progress?.toFixed(0)}%)</span>
      </div>
    );
  } else if (!adsData || !adsData.ads || adsData.ads?.size === 0) {
    content = (
      <div className="flex flex-col gap-2 items-center justify-center mt-20">
        No Data available
        <Button
          variant="primary"
          onClick={onFetchData}
          loading={fetchingData && !finished}
        >
          Refetch Data
        </Button>
      </div>
    );
  } else if (adsData.ads.size > 0) {
    content = (
      <div className="w-full h-full rounded-xl border border-gray-200 dark:border-gray-700 p-4 flex flex-col space-y-2">
        {tablesToExport.map((tableToExport) => {
          if (tableToExport !== "all") {
            const hasData = (adsData.ads.get(tableToExport)?.length ?? 0) > 0;
            if (!hasData) return null;
            // the regular influencer data is considered "old"
            // the influencer data that lives under the scope "influencer_module"
            // is now the influencer data we use in the future
            const label =
              tableToExport === "influencer"
                ? "Influencer (old)"
                : CONSTANT_LABELS[tableToExport];
            return (
              <div key={tableToExport} className="flex flex-col space-y-1">
                <p className="font-semibold text-sm">
                  {label ??
                    tableToExport.substring(0, 1).toUpperCase() +
                      tableToExport.substring(1)}{" "}
                </p>
                <DownloadFileButton
                  onDownloadFile={onDownloadFile}
                  channel={tableToExport}
                  exportType="all"
                />
                {!CHANNELS_WITHOUT_BASESCORE_TYPE.includes(tableToExport) &&
                  ["Campaigns", "Adsets", "Ads"].map((el) => {
                    return (
                      <div
                        className="flex items-center"
                        key={`${tableToExport}-${el}`}
                      >
                        <p className="font-semibold text-xs w-20">{el}</p>
                        <DownloadFileButton
                          onDownloadFile={onDownloadFile}
                          channel={tableToExport}
                          exportType={el.toLowerCase() as ExportType}
                        />
                      </div>
                    );
                  })}
                {!CHANNELS_WITHOUT_BASESCORE_TYPE.includes(tableToExport) ? (
                  <div className="h-2" />
                ) : null}
              </div>
            );
          } else {
            return (
              <DownloadFileButton
                onDownloadFile={onDownloadFile}
                channel={tableToExport}
                exportType="all"
                key={`${tableToExport}-all`}
              />
            );
          }
        })}
      </div>
    );
  }
  return (
    <Modal onClose={onModalClose} open={exportModalOpen} title={"Export Data"}>
      <div className="min-h-[256px] flex flex-col">
        <p className="flex items-center text-sm">
          Exporting data for {isSameDate ? "date: " : "time-range: "}
          &nbsp;
          <b>
            {isSameDate
              ? dayjs(startTime).format("MMM DD, YYYY")
              : dayjs(startTime).format("MMM DD, YYYY") +
                " - " +
                dayjs(endTime).format("MMM DD, YYYY")}
          </b>
        </p>
        {includesCurrentDay && (
          <Note type="warning" filled className="mt-2" label="Note">
            For time-ranges that include the present day we exclude the spent
            amount, because data reported to our systems may be delayed and can
            cause discrepancies.
          </Note>
        )}
        <div className="mt-4">{content}</div>
      </div>
    </Modal>
  );
};

export default ExportModal;
