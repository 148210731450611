import React from "react";

const CreativeDashboardIcon = ({
  color,
  className,
}: {
  color: string;
  className?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.5 13H3.5C3.2 13 3 13.2 3 13.5V20.5C3 20.8 3.2 21 3.5 21H10.5C10.8 21 11 20.8 11 20.5V13.5C11 13.2 10.8 13 10.5 13ZM10 20H4V14H10V20ZM10.5 3H3.5C3.2 3 3 3.2 3 3.5V10.5C3 10.8 3.2 11 3.5 11H10.5C10.8 11 11 10.8 11 10.5V3.5C11 3.2 10.8 3 10.5 3ZM10 10H4V4H10V10ZM20.5 3H13.5C13.2 3 13 3.2 13 3.5V10.5C13 10.8 13.2 11 13.5 11H20.5C20.8 11 21 10.8 21 10.5V3.5C21 3.2 20.8 3 20.5 3ZM20 10H14V4H20V10ZM20.5 16.5H17.5V13.5C17.5 13.2 17.3 13 17 13C16.7 13 16.5 13.2 16.5 13.5V16.5H13.5C13.2 16.5 13 16.7 13 17C13 17.3 13.2 17.5 13.5 17.5H16.5V20.5C16.5 20.8 16.7 21 17 21C17.3 21 17.5 20.8 17.5 20.5V17.5H20.5C20.8 17.5 21 17.3 21 17C21 16.7 20.8 16.5 20.5 16.5Z"
        fill={color}
      />
    </svg>
  );
};

export default CreativeDashboardIcon;
