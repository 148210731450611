import { useCallback, useMemo, useRef, useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { Combobox } from "@headlessui/react";
import { useStores } from "@lib/api-hooks";
import {
  MagnifyingGlassIcon,
  BuildingOfficeIcon,
  NoSymbolIcon,
} from "@heroicons/react/24/outline";
import { useClickAway } from "@lib/hooks";
import { SelectTypes } from "@lib/util-types";
import { getSelectColors } from "@ui/select/styles";
import { classNames } from "@lib/util-functions";
import { useOrganisations } from "@lib/api-hooks/useOrganisations";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import { Store } from "@api/types/backendTypes";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@ui";

type Props = {
  storeOptions?: string[];
  onStoreChanged: (value: any) => void;
  type?: SelectTypes;
  multiple?: boolean;
};

const SidebarStoreSelector: React.FC<Props> = ({
  storeOptions = [],
  onStoreChanged,
  type = "default",
  multiple = true,
}) => {
  const scope = "kytron::read_events";
  const ref = useRef<HTMLDivElement | null>(null);
  const { data: stores } = useStores({ scope });
  const { data: organisations } = useOrganisations();
  const { selectedOrganisation } = useSelectedStores();
  const [query, setQuery] = useState("");
  const colors = useMemo(() => getSelectColors("lite"), []);

  useClickAway(ref, () => {
    setQuery("");
  });

  const filteredOrganisations = useMemo(() => {
    if (!stores || !organisations) return [];
    const organisationsWithStores = organisations.map((organisation) => {
      const orgaStores = (organisation?.customerSites
        .map((site) => {
          const store = stores.find((el) => el.csid === site.id);
          return store;
        })
        .filter((el) => !!el?.csid) ?? []) as Store[];
      return { ...organisation, customerSites: orgaStores };
    });
    let currentOrgas = [...organisationsWithStores];
    if (query !== "") {
      currentOrgas = organisationsWithStores?.filter((organisation) => {
        const orgaNameBool = organisation.name
          .toLowerCase()
          .includes(query.toLowerCase());
        const storeNameBool = organisation.customerSites.reduce(
          (prev, store, i, arr) => {
            if (prev) return true; // eject early because it's already true
            return (
              store.name.toLowerCase().includes(query.toLowerCase()) ||
              store.description.toLowerCase().includes(query.toLowerCase()) ||
              store?.url.toLowerCase().includes(query.toLowerCase())
            );
          },
          false
        );

        return orgaNameBool || storeNameBool;
      });
    }
    return currentOrgas.sort((a, b) =>
      selectedOrganisation === b.id ? 1 : -1
    );
  }, [organisations, query, selectedOrganisation, stores]);

  const selectedStores = useMemo(
    () => stores?.filter((store) => storeOptions.includes(store.csid)),
    [storeOptions, stores]
  );

  const selectedOrganisationWithStores = useMemo(() => {
    const organisation = organisations?.find(
      (el) => el.id === selectedOrganisation
    );
    const orgaStores = (organisation?.customerSites
      .map((site) => {
        const store = stores?.find((el) => el.csid === site.id);
        return store;
      })
      .filter((el) => !!el?.csid) ?? []) as Store[];
    return { ...organisation, customerSites: orgaStores };
  }, [organisations, selectedOrganisation, stores]);

  const onSelectStore = useCallback(
    (value: any, isOrganisation?: boolean) => {
      const currentStores = storeOptions;

      if (isOrganisation) {
        if (currentStores.length < 2) {
          onStoreChanged(value);
        } else {
          onStoreChanged([currentStores[0]]);
        }
      } else {
        if (multiple) {
          onStoreChanged(value);
        } else {
          const valueArr = typeof value === "string" ? value.split(",") : value;
          onStoreChanged([valueArr[valueArr.length - 1]]);
        }
      }
    },
    [multiple, onStoreChanged, storeOptions]
  );

  return (
    <Combobox
      ref={ref}
      as="div"
      value={storeOptions}
      onChange={onSelectStore}
      id="store-selector-select"
      // @ts-ignore
      multiple={multiple}
    >
      <div className="relative">
        <Combobox.Button className="relative bg-gray-300 dark:bg-secondary rounded-md pl-2 pr-5 h-12 w-full text-left">
          {selectedOrganisationWithStores ? (
            <div className="leading-none text-foreground dark:text-white">
              <p className="text-sm font-semibold truncate mr-1">
                {selectedStores?.length === 1
                  ? selectedStores?.map((store) => store.description).join(", ")
                  : `${selectedStores?.length ?? 0} Stores selected`}
              </p>
              <p className="text-xs truncate mr-1">
                {selectedOrganisationWithStores.name}
              </p>
            </div>
          ) : (
            <div>
              <p className="text-sm font-semibold truncate mr-2">
                No organisation selected
              </p>
            </div>
          )}
          <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-foreground dark:text-white"
              aria-hidden="true"
            />
          </div>
        </Combobox.Button>
        <Combobox.Options className="absolute scrollbar z-menu mt-1 max-h-64 w-full overflow-y-auto overflow-x-hidden rounded-md bg-gray-100 dark:bg-gray-800 py-1 text-base shadow-lg ring-1 ring-secondary ring-opacity-5 focus:outline-none sm:text-sm">
          <div className="relative px-2 mb-2">
            <div className="absolute inset-y-0 left-2 flex items-center rounded-r-md px-2 focus:outline-none">
              <MagnifyingGlassIcon
                className="h-4 w-4 text-foreground"
                aria-hidden="true"
              />
            </div>
            <Combobox.Input
              className={`w-full rounded-lg border  h-8 pl-8 pr-10 shadow-sm ${colors} focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary text-sm`}
              onChange={(event) => setQuery(event.target.value)}
              value={query}
              displayValue={() => query}
              autoComplete="off"
              id="sidebar-store-selector-input"
            />
          </div>
          {filteredOrganisations.length > 0 ? (
            <>
              {filteredOrganisations.map((organisation) => {
                return (
                  <div
                    key={organisation.id}
                    className="store-selector-organisation"
                    id={"store-selector-organisation-" + organisation.id}
                  >
                    <button
                      className={`font-semibold w-full py-2 px-3 ${
                        selectedOrganisation === organisation.id
                          ? "bg-primary text-on-primary"
                          : "hover:bg-gray-200 dark:hover:bg-gray-700"
                      } flex items-center`}
                      onClick={() =>
                        onSelectStore(
                          organisation.customerSites.map((el) => el.csid),
                          true
                        )
                      }
                    >
                      <BuildingOfficeIcon className="h-4 w-4 mr-1 text-foreground-soft flex-none" />
                      <span className="truncate">{organisation.name}</span>
                    </button>
                    <TooltipProvider>
                      {organisation.customerSites.map((store) => {
                        const disabled =
                          selectedStores &&
                          selectedStores?.length > 0 &&
                          store.pg_idx !== selectedStores[0]?.pg_idx &&
                          organisation.id === selectedOrganisation;
                        return (
                          <Combobox.Option
                            id={"store-selector-item-" + store.csid}
                            key={store.csid}
                            disabled={disabled}
                            value={store.csid}
                            className={({ active, selected }) =>
                              classNames(
                                "relative cursor-default select-none py-2 pl-8 pr-4 disabled:text-foreground-soft",
                                active
                                  ? "bg-gray-200 dark:bg-gray-700"
                                  : selected
                                    ? "bg-gray-200 dark:bg-gray-700 "
                                    : "text-foreground"
                              )
                            }
                          >
                            {({ active, selected }) => (
                              <div>
                                <Tooltip>
                                  <TooltipTrigger>
                                    {(disabled || selected) && (
                                      <span
                                        className={classNames(
                                          "absolute inset-y-0 left-0 flex items-center pl-1.5",
                                          disabled
                                            ? "text-foreground-soft"
                                            : active
                                              ? "text-foreground"
                                              : "text-primary"
                                        )}
                                      >
                                        {disabled ? (
                                          <NoSymbolIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        )}
                                      </span>
                                    )}
                                    <div className="flex">
                                      <span
                                        className={classNames(
                                          "truncate",
                                          selected && "font-semibold",
                                          "text-xs"
                                        )}
                                      >
                                        {store.description}
                                      </span>
                                    </div>
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    <div className="max-w-sm">
                                      <p>
                                        {store.name} - {store.description}
                                      </p>
                                      {disabled ? (
                                        <>
                                          <p className="text-sm mt-2">
                                            <b>
                                              This store can&apos;t be selected
                                              because it lives inside a
                                              different database as the current
                                              selected store(s).
                                            </b>
                                          </p>
                                          <p className="mt-1 text-sm">
                                            Deselect the current store(s) and
                                            try again.
                                          </p>
                                        </>
                                      ) : null}
                                    </div>
                                  </TooltipContent>
                                </Tooltip>
                              </div>
                            )}
                          </Combobox.Option>
                        );
                      })}
                    </TooltipProvider>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="relative px-2 py-2 ">
              <p className="text-sm font-semibold">No stores found...</p>
            </div>
          )}
        </Combobox.Options>
      </div>
    </Combobox>
  );
};

export default SidebarStoreSelector;
