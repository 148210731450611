import React from "react";

const GoogleChannelIcon = ({
  color,
  className,
}: {
  color: string;
  className?: string;
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.00012 14.25C6.9722 14.2502 5.9601 13.9969 5.05348 13.5125C4.14686 13.0281 3.3737 12.3276 2.80252 11.4729C2.23134 10.6183 1.87976 9.63604 1.77892 8.61309C1.67809 7.59013 1.83112 6.55809 2.22446 5.60841C2.6178 4.65873 3.23929 3.82072 4.03389 3.16863C4.82849 2.51654 5.77166 2.0705 6.77983 1.87002C7.78801 1.66954 8.83007 1.72082 9.81369 2.01931C10.7973 2.31781 11.6921 2.85429 12.4189 3.58125C12.4888 3.65093 12.5443 3.73372 12.5821 3.82489C12.62 3.91605 12.6395 4.01379 12.6395 4.1125C12.6395 4.21121 12.62 4.30895 12.5821 4.40011C12.5443 4.49128 12.4888 4.57407 12.4189 4.64375C12.2766 4.78196 12.086 4.85928 11.8876 4.85928C11.6892 4.85928 11.4987 4.78196 11.3564 4.64375C10.9175 4.20029 10.3946 3.84866 9.8184 3.60936C9.24217 3.37007 8.62405 3.24791 8.00012 3.25C7.09284 3.25064 6.20473 3.51109 5.44083 4.00057C4.67692 4.49004 4.06917 5.18805 3.68946 6.01204C3.30975 6.83603 3.17397 7.75153 3.29817 8.65026C3.42237 9.54899 3.80137 10.3934 4.39032 11.0835C4.97928 11.7736 5.75356 12.2806 6.62159 12.5446C7.48962 12.8085 8.41509 12.8183 9.28852 12.5729C10.162 12.3274 10.9468 11.8369 11.5503 11.1595C12.1538 10.482 12.5506 9.64589 12.6939 8.75H8.00012C7.8012 8.75 7.61044 8.67098 7.46979 8.53033C7.32913 8.38968 7.25012 8.19891 7.25012 8C7.25012 7.80109 7.32913 7.61032 7.46979 7.46967C7.61044 7.32902 7.8012 7.25 8.00012 7.25H13.5001C13.699 7.25 13.8898 7.32902 14.0304 7.46967C14.1711 7.61032 14.2501 7.80109 14.2501 8C14.2468 9.65659 13.5873 11.2444 12.4159 12.4158C11.2445 13.5872 9.6567 14.2467 8.00012 14.25Z"
        fill={color}
      />
    </svg>
  );
};

export default GoogleChannelIcon;
