import { LockClosedIcon } from "@heroicons/react/24/solid";
import React from "react";

type ScopedRouteInterface = {
  accessFlag: boolean;
  title: string;
  message: string;
};

export function ScopedRoute(props: ScopedRouteInterface) {
  const { accessFlag, title, message } = props;

  return accessFlag ? (
    <div className="w-full h-screen flex flex-col justify-center items-center overflow-y-auto">
      <div className="max-w-[90%] w-96 md:w-[500px] lg:w-[668px] flex flex-col rounded-3xl bg-gray-200 dark:bg-gray-700 border-2 border-gray-200 dark:border-gray-700 px-8 py-14">
        <div className="flex items-center m-auto justify-center bg-indigo-600 rounded-full w-24 h-24">
          <LockClosedIcon className="h-12 w-12 text-foreground" />
        </div>
        <p className="text-foreground text-4xl font-bold my-5 text-center">
          {title}
        </p>
        <p className="text-gray-200 text-center mb-5">{message}</p>
        <p className="text-gray-300 text-center mb-5 mt-5">
          <a
            href="https://www.tracify.ai/pages/imprint"
            target="_blank"
            rel="noreferrer"
          >
            Imprint
          </a>{" "}
          |{" "}
          <a
            href="https://www.tracify.ai/pages/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  ) : null;
}
